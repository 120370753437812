import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container, Input } from '@material-ui/core'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CourseWrapper from '../../components/CourseWrapper'

import { PageTitle } from '../../components/PageTitle'
import { SectionTitle, SearchBar } from '../../styles/pages/presencial'

export default function PosGraduacaoSemiPresencialPage(props) {
  // const { data } = props
  // const { edges } = data.allMarkdownRemark

  // const [filteredCourses, setFilteredCourses] = useState(edges)
  // const [query, setQuery] = useState('')
  // const [searchResults, setSearchResults] = useState([])

  // useEffect(() => {
  //   const posts = filteredCourses.filter(({ node }) =>
  //     node.frontmatter.name.toLowerCase().includes(query.toLowerCase())
  //   )
  //   setSearchResults(posts)
  // }, [query.toLowerCase()])

  return (
    <Layout>
      <SEO title="Pós-Graduação Semipresencial" />

      <Container style={{ marginTop: 70 }}>
        <PageTitle>Pós-Graduação Semipresencial</PageTitle>

        <p
          style={{
            marginTop: 50,
            fontSize: 18,
            color: '#333',
            textAlign: 'justify',
            lineHeight: '1.8em',
            wordSpacing: 8,
          }}
        >
          A Pós-Graduação semipresencial ocorre na Sede, nos Polos e em endereços
          específicos, com disciplinas presenciais, normalmente aos sábados - de
          08 as 12h e de 13 as 18h, e disciplinas virtuais mediante acesso ao AVA*
          – Ambiente Virtual de Aprendizagem da Faculdade Jardins.
          <br /><br />

          <span style={{ fontSize: 15, color: '#555' }}>
            *AVA – Ambiente Virtual de Aprendizagem da Faculdade Jardins é uma
            Plataforma de Aprendizagem on-line completa para otimizar a formação
            de nossos alunos, dispondo, inclusive, de vídeos aula, e-books,
            biblioteca virtual, periódicos virtuais, materiais de aula dos
            docentes e tutores, slides, artigos, textos, hipertextos, links,
            chats, fóruns, exercícios, avaliações, etc..
          </span>
        </p>
      </Container>
      <Container disableGutters={true} style={{ marginTop: 60 }}>
        <SectionTitle>Cursos de Pós-Graduação</SectionTitle>

        <SearchBar>
          <Input
            placeholder="Pesquisar curso"
            // value={query}
            // onChange={e => setQuery(e.target.value)}
            style={{ padding: 6, borderRadius: 5, border: '2px solid' }}
          />
        </SearchBar>

        {/* <div>
          {searchResults.map(({ node }) => (
            <CourseWrapper
              name={node.frontmatter.name}
              img={node.frontmatter.image.publicURL}
              duration={node.frontmatter.duration}
              price={node.frontmatter.price}
              parcel={node.frontmatter.parcelas}
              link={node.fields.slug}
              key={node.frontmatter.price}
              graduation={node.frontmatter.category}
            />
          ))}
        </div> */}
      </Container>
    </Layout>
  )
}

// export const pageQuery = graphql`
//   query {
//     allMarkdownRemark(
//       limit: 2000
//       sort: { fields: [frontmatter___name], order: ASC }
//       filter: { frontmatter: { category: { eq: "pos-graduacao presencial" } } }
//     ) {
//       edges {
//         node {
//           fields {
//             slug
//           }
//           frontmatter {
//             name
//             area
//             category
//             price
//             duration
//             parcelas
//             image {
//               publicURL
//             }
//           }
//         }
//       }
//     }
//   }
// `
